@import '~sweetalert2/src/variables';
// Baseado no @sweetalert2/theme-dark
// npm install @sweetalert2/theme-dark --save

$swal2-dark-theme-black: var(--v-swal2Bg-base, #19191a);
$swal2-dark-theme-white: var(--v-swal2Text-base, #e1e1e1);
$swal2-footer-border-color: var(--v-swal2Border-base, #555);



// $swal2-dark-theme-black: #19191a;
// $swal2-dark-theme-white: #e1e1e1;
$swal2-outline-color: lighten($swal2-outline-color, 10%);

$swal2-background: $swal2-dark-theme-black;
$swal2-content-color: $swal2-dark-theme-white;
$swal2-title-color: $swal2-dark-theme-white;
$swal2-backdrop: rgba($swal2-dark-theme-black, .75);

// FOOTER
// $swal2-footer-border-color: #555;
$swal2-footer-color: var(--v-swal2Text2-base);

// TIMER POGRESS BAR
$swal2-timer-progress-bar-background: rgba($swal2-dark-theme-white, .6);

// INPUT
$swal2-input-color: $swal2-dark-theme-white;
$swal2-input-background: var(--v-swal2Bg2-base);

// VALIDATION MESSAGE
$swal2-validation-message-background: var(--v-swal2Bg2-base);
$swal2-validation-message-color: $swal2-dark-theme-white;

// QUEUE
$swal2-progress-step-background: var(--v-swal2Bg2-base);

// COMMON VARIABLES FOR CONFIRM AND CANCEL BUTTONS
$swal2-button-focus-box-shadow: 0 0 0 1px $swal2-background, 0 0 0 3px $swal2-outline-color;

// TOAST
$swal2-toast-background: $swal2-dark-theme-black;
$swal2-toast-button-focus-box-shadow: 0 0 0 1px $swal2-background, 0 0 0 3px $swal2-outline-color;

@import '~sweetalert2/src/sweetalert2';
