
























@import '@design';

.mAppLoader {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: $dark-gray4;
  z-index: 900;
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;

  .loader {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 220px;
    height: 220px;
    overflow: hidden;
    filter: drop-shadow(2px 4px 5px $dark-gray3);

    .transition {
      height: 200px !important;
    }
    .loadingIcon {
      @extend %typography-xxlarge;

      display: block;
      margin: 0 auto;
      width: 70px;
      height: 70px;

      // stylelint-disable-next-line selector-class-pattern
      &:global(.v-enter-active) {
        transition: opacity 1s;
      }
      // stylelint-disable-next-line selector-class-pattern
      &:global(.v-enter) {
        opacity: 0;
      }
    }

  }
}
