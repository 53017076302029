$font-primary-text: 'Roboto', sans-serif;
$primary-font: 'Roboto', sans-serif;

$font-secondary-text: 'Montserrat', sans-serif;
$secondary-font: 'Montserrat', sans-serif;

$font-input: 'Roboto', sans-serif;

$font-landing: 'Roboto Regular', sans-serif;

$system-default-font-family: $font-primary-text;

$heading-font-family: $system-default-font-family;
$heading-font-weight: 600;

$content-font-family: $system-default-font-family;
$content-font-weight: 400;

%font-heading {
  font-family: $heading-font-family;
  font-weight: $heading-font-weight;
  color: $color-heading-text;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

%font-content {
  font-family: $content-font-family;
  font-weight: $content-font-weight;
  color: $color-text;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

%fontContentHeaderTitle {
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #333333;
}

%font-navbar {
  font-family: $font-secondary-text;
  font-weight: 500;
  font-size: 19px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$roboto-font-path: '@assets/fonts/Roboto';
@font-face {
  font-family: 'Roboto Thin';
  src: url('#{$roboto-font-path}/Roboto-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto ThinItalic';
  src: url('#{$roboto-font-path}/Roboto-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Light';
  src: url('#{$roboto-font-path}/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto LightItalic';
  src: url('#{$roboto-font-path}/Roboto-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Regular';
  src: url('#{$roboto-font-path}/Roboto-Regular.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Italic';
  src: url('#{$roboto-font-path}/Roboto-Italic.ttf');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Medium';
  src: url('#{$roboto-font-path}/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto MediumItalic';
  src: url('#{$roboto-font-path}/Roboto-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Bold';
  src: url('#{$roboto-font-path}/Roboto-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto BoldItalic';
  src: url('#{$roboto-font-path}/Roboto-BoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Black';
  src: url('#{$roboto-font-path}/Roboto-Black.ttf') format('ttf');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto BlackItalic';
  src: url('#{$roboto-font-path}/Roboto-BlackItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}


$sans-font-path: '@assets/fonts/SansPro';
@font-face {
  font-family: 'SansPro Thin';
  src: url('#{$sans-font-path}/SourceSansPro-ExtraLight.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'SansPro ThintItalic';
  src: url('#{$sans-font-path}/SourceSansPro-ExtraLightItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SansPro Light';
  src: url('#{$sans-font-path}/SourceSansPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'SansPro LightItalic';
  src: url('#{$sans-font-path}/SourceSansPro-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SansPro Regular';
  src: url('#{$sans-font-path}/SourceSansPro-Regular.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SansPro Italic';
  src: url('#{$sans-font-path}/SourceSansPro-Italic.ttf');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SansPro Medium';
  src: url('#{$sans-font-path}/SourceSansPro-SemiBold.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'SansPro MediumItalic';
  src: url('#{$sans-font-path}/SourceSansPro-SemiBoldItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SansPro Bold';
  src: url('#{$sans-font-path}/SourceSansPro-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SansPro BoldItalic';
  src: url('#{$sans-font-path}/SourceSansPro-BoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SansPro Black';
  src: url('#{$sans-font-path}/SourceSansPro-Black.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SansPro BlackItalic';
  src: url('#{$sans-font-path}/SourceSansPro-BlackItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

// typograph styleguide
$gray1: #efeef0;
$gray2: #d1d5da;
$gray3: #586069;
$gray4: #464646;

$gray-scale-2: #586069;

$blue-scale-4: #0a84ff;

.text-big-title {
  font-size: 2.5rem; // 40 px
  letter-spacing: -0.5px;
  line-height: 2rem;
  font-display: swap;
}

.text-title-1 {
  font-family: 'SansPro', sans-serif;
  font-size: 2.125rem; // 34 px
  font-display: swap;
}

.text-title-2 {
  font-family: 'SansPro', sans-serif;
  font-size: 1.75rem; // 28 px
  font-display: swap;
}

.text-title-3 {
  font-family: 'SansPro', sans-serif;
  font-size: 1.5rem; // 24px
  font-display: swap;
}

.text-title-4 {
  font-family: 'SansPro', sans-serif;
  font-size: 1.125rem; // 18 px
  font-display: swap;
}

.text-headline {
  font-family: 'SansPro', sans-serif;
  font-size: 1.375rem; // 22 px
  letter-spacing: 0.25px;
  font-display: swap;
}

.text-headline-2 {
  font-family: 'SansPro', sans-serif;
  font-size: 1.125rem; // 18 px
  font-display: swap;
}

.text-headline-3 {
  font-size: 1rem; // 16 px
  letter-spacing: 0.15px;
  font-display: swap;
}

.text-body-1 {
  font-family: 'SansPro', sans-serif;
  font-size: 1.125rem; // 18 px
  letter-spacing: 0.15px;
  font-display: swap;
}

.text-body-2 {
  font-family: 'SansPro', sans-serif;
  font-size: 1rem; // 16 px
  letter-spacing: 0.15px;
  font-display: swap;
}

.text-body-3 {
  font-family: 'SansPro', sans-serif;
  font-size: 0.875rem; // 14 px
  letter-spacing: 0.15px;
  font-display: swap;
}

.text-body-large {
  font-family: 'SansPro', sans-serif;
  font-size: 1.125rem; // 18 px
  letter-spacing: 0.15px;
  font-weight: bold;
  font-display: swap;
}

.text-body-large-2 {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1rem; // 16 px
  letter-spacing: 0.15px;
  font-weight: bold;
  font-display: swap;
}

.text-caption {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 0.75rem; // 12 px
  letter-spacing: 0.4px;
  font-display: swap;
}

.text-overline {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 0.625rem; // 10 px
  letter-spacing: 1px;
  font-weight: 500;
  font-display: swap;
}

.text-ligth {
  font-weight: lighter;
}

.text-bold-thin {
  font-weight: 500;
}

.text-bold {
  font-weight: 600;
}

.text-bolder {
  font-weight: 700;
}

// * colors
.text-white {
  color: white;
}

.text-black {
  color: black;
}

.text-gray-0 {
  color: $soft-gray-0;
}

.text-gray {
  color: $gray-scale-2;
}

.paragraph-gray {
  color: rgba($dark-gray, 0.8);
}

.text--green {
  color: $soft-green !important;
}

.text--blue {
  color: $blue-scale-4 !important;
}

.text--red {
  color: $color-secondary !important;
}

.text-upper {
  text-transform: uppercase !important;
}

.text-lower {
  text-transform: lowercase;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

text--sans {
  font-family: 'SansPro', sans-serif !important;
  font-display: swap;
}

// #mixins

@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

/*
p {
  @include font-size(14px)
}
*/

@mixin text(
  $size: null,
  $weight: null,
  $lineHeight: null,
  $color: null,
  $case: null
) {
  @if $size != null {
    font-size: $size;
  }

  @if $lineHeight != null {
    line-height: $lineHeight;
  }

  @if $weight != null {
    font-weight: $weight;
  }

  @if $color != null {
    color: $color;
  } @else {
    color: red;
  }

  @if $case != null {
    text-transform: $case;
  } @else {
    text-transform: normal;
  }
}
// example
// *passing all values
// &-title {
//   @include text(16px, 19px, 600);
// }
// **passing some values
// &-author {
//   @include text($weight: 800, $size: 12px);
// }
