




















@import '@design';

.loadingIcon {
  @extend %typography-xxlarge;

  display: block;
  position: fixed;
  top: 50%;
  left: 50%;

  // stylelint-disable-next-line selector-class-pattern
  &:global(.v-enter-active) {
    transition: opacity 1s;
  }
  // stylelint-disable-next-line selector-class-pattern
  &:global(.v-enter) {
    opacity: 0;
  }
}
