








































































































































































































































































































































































@import '@design';

.app-footer--home {
  @include flexbox(column, nowrap, center, flex-start);
  width: 100%;
  height: fit-content;
  min-height: 300px;
  margin: 0;
  padding: 0;
  background-color: $soft-gray-1 !important;
  box-sizing: border-box;

  @include for-phone-only {
    width: 100vw;
  }

  @include for-desktop-up {
    align-items: center;
  }

  .footer-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    width: fit-content;
    max-width: 1300px !important;
    background-color: $soft-gray-1;

    margin: 0 0 24px 0;

    @include for-desktop-up {
      max-width: 1200px;
    }

    .footer--wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      justify-content: center;
      height: 100%;
      width: fit-content;
      margin: 0;
      padding: 0 32px;

      @include for-phone-only {
        max-width: 100vw;
      }

      @include for-desktop-up {

      }

      .section--enterprise {
        @include flexbox(column, nowrap, flex-start, flex-start);
        min-height: 150px;
        min-width: 250px;
        max-width: 250px;
        padding: 16px 0;
        text-align: left;

        @include for-phone-only {
          max-width: 100%;
          @include flexbox(column, nowrap, center, flex-start);
          margin: 32px 0 0 0;
        }

        @include for-desktop-up {

        }

        .enterprise--brand {
          min-width: 100px;
          padding: 16px 0;
          margin: 0;

          @include for-phone-only {
            margin: 0 0 16px 0;
            padding: 8px 0;
          }

          .encapsuled-link {
            padding: 0;

            @include for-phone-only {
              max-width: 120px;
            }

            .image-brand {
              max-height: 40px;
              width: auto;
              object-fit: cover;
              margin: 0 0 0 -4px;

              @include for-phone-only {
                align-self: flex-start;
                max-height: 32px;
              }
            }

          }
        }

        .enterprise-address {
          margin: 0 0 24px 0;
          min-width: 320px;

         @include for-phone-only {
            margin: 0;
            min-width: 220px;
            text-align: center;
          }
        }

        .midias-content {
          width: fit-content;
          height: inherit;
          @include flexbox(row, nowrap,  center, space-between);
          background: transparent;
          margin: 0;
          padding: 8px 0;

          @include for-phone-only {
          }

          .midias-item {
            width: 24px;
            height: 24px;
            margin: 0 8px 0 0;
          }
        }
      }

      .section--routes {
        @include flexbox(row, nowrap, flex-start, flex-start);
        flex: 1 1 0;
        width: 100%;
        height: 250px;
        padding: 32px 16px;

        @include for-phone-only {
          display: none;
        }

        .collumn-section {
          @include flexbox(column, nowrap, flex-start, flex-start);
          min-width: 120px;
          height: 100%;
          margin: 0 32px 0 0;
          padding: 0;

          &__header {
            margin: 0 0 10px 0;
            .set-title {
              @include text(14px, 500, 1.35, $color-text, normal);
              letter-spacing: 0.5px;
            }
          }

          &__content-list {
            @include flexbox(column, nowrap, flex-start, flex-start);
            width: 100%;

            .redirect-links {
              text-decoration: none;
              margin: 0 0 8px 0;

              span {
                color: rgba($dark-gray, .7) !important;
                text-decoration: none;

                &:hover {
                  color: rgba($dark-gray, .9) !important;
                  transition: all .2s ease-in-out;
                }
              }
            }
          }

        }

      }
    }

    .footer-rights {
      @include flexbox(row, nowrap, center, center);
      width: 100%;
      background: transparent;
      margin: 0;
      padding: 16px 0;
      border-top: 1px solid rgba($dark-gray, .4);

      @include for-phone-only {
        display: none;
        @include flexbox(column, nowrap, center, center);
        text-align: center;
      }

      @include for-tablet-portrait-up {
        & span:first-child {
          margin: 0 24px 0 0;
        }
      }

      .text-rights {
        @include text(14px, 500, 1.35, rgba($dark-gray, .7), uppercase);
        font-family: 'Museosans';
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 1px;
        text-align: left;
        text-transform: lowercase;
      }
    }
  }

}

