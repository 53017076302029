









































































































































// Allow element/type selectors, because this is global CSS.
// stylelint-disable selector-max-type, selector-class-pattern

// Normalize default styles across browsers,
// https://necolas.github.io/normalize.css/
@import '~normalize.css/normalize.css';
// Style loading bar between pages.
// https://github.com/rstacruz/nprogress
@import '~nprogress/nprogress.css';


@import '~sweetalert2/src/variables';
//$swal2-background: #990000;
@import '~sweetalert2/src/sweetalert2';



// Design variables and utilities from src/design.
@import '@design';
// @import '~typeface-montserrat/index.css';


*,
*::before,
*::after {
  box-sizing: border-box;
}

#app {
  @extend %typography-small;
}

html {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;

  @include for-phone-only {
    width: 100vw;
    overflow-x: hidden !important;
  }
}

body {
  height: 100% !important;
  width: 100% !important;
  margin: 0;
  padding: 0;
  background-color: #fff;
  // border: 1px solid green;

  width: 100vw;
  overflow-x: hidden !important;

  * {
    scrollbar-width: thin;
    scrollbar-color: var(#E3EEF0);
  }

  *::-webkit-scrollbar {
    width: 2px;
  }
  *::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.05);
  }
  *::-webkit-scrollbar-thumb {
    background-color: var(--v-scroll-base, #adadad);
    border-radius: 2px;
    margin-right: 1px !important;
  // border: 3px solid #121212;
  }
}

#app {
  /*font-family: 'Avenir', Helvetica, Arial, sans-serif;*/
  /*font-family: Montserrat;*/
  $layer-root: 0;

  @extend %font-content;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $color-text;
  height: 100%;
  width: 100%;
  padding: 0;
  background-color: unset;
  position: absolute;
  z-index: $layer-root;
  // border: 1px solid green;

  @include for-phone-only {
    width: 100vw;
    // overflow-x: hidden !important;
  }

  * {
    scrollbar-width: thin;
    scrollbar-color: var(#E3EEF0);
  }

  *::-webkit-scrollbar {
    width: 4px;
  }
  *::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.05);
  }
  *::-webkit-scrollbar-thumb {
    background-color: var(--v-scroll-base, #adadad);
    border-radius: 2px;
    margin-right: 1px !important;
  // border: 3px solid #121212;
  }

  @include for-desktop-up {

    * {
      scrollbar-width: thin;
      scrollbar-color: var(#5a5a5a);
    }

    *::-webkit-scrollbar {
      width: 7px;
    }
    *::-webkit-scrollbar-track {
      background: rgba(255, 255, 255, 0.05);
    }
    *::-webkit-scrollbar-thumb {
      background-color: var(--v-scroll-base, #adadad);
      border-radius: 20px;
      margin-right: 4px !important;
    // border: 3px solid #121212;
    }
  }
}

#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: $color-text;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

// #myGlobalModal {
//   z-index: 9999;
// }


.clear-float {
  clear: both;
}

.pointer {
  cursor: pointer;
}

// ===
// Base element styles
// ===

a,
a:visited {
  color: $color-link-text;
}

h1 {
  @extend %typography-xxlarge;
}

h2 {
  @extend %typography-xlarge;
}

h3 {
  @extend %typography-large;
}

h4 {
  @extend %typography-medium;
}

h5,
h6 {
  @extend %typography-small;
}


// ===
// Base form
// ===

.formItemSubSection {
  margin-left: 10px;
  margin-top: 30px;
  font-size: 10px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.4;
  letter-spacing: 0.3px;
  color: #707070;
}

.formItem {
  position: relative;
  padding-left: 10px;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  /*box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.12);*/
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  .formItemImg {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .formItemDesc {
    flex: 10;

    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;


    -webkit-flex-direction: column;
    flex-direction: column;


    .formItemHead {
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.25;
      letter-spacing: normal;
      color: rgba(0, 0, 0, 0.87);
      padding-left: 8px;
      padding-right: 8px;
    }
    .formItemSub {
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: rgba(0, 0, 0, 0.54);
      padding-left: 8px;
      padding-right: 8px;
    }
  }
  .formItemAction {
    flex: 1;
    align-self: center;
    .md-radio, .md-switch {
      margin: 7px 16px 7px 0;
    }
    .formItemControl {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
    }
  }

  .formItemAction .md-checked + .formItemControl  {
    background-color: rgba(0, 0, 0, 0.05);
  }

  .formItemHelp {
    color: rgba(0, 0, 0, 0.12);
    align-self: center;
  }



}

.formItemFirst {
  /*box-shadow: inset 0 -1px 0 -1px rgba(0, 0, 0, 0.12);*/
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}

.formItemText {
  margin-left: 10px;
}

// ====
// Base sheet
// ====

// #sheet
.my-sheet--default {
  width: 700px;
  max-width: 700px;
  min-width: 450px;
  background-color: #fff;
  border: 1px solid $soft-gray-1;
  border-radius: 10px;
  box-shadow: $button-shadow;
  overflow: hidden;
  padding: 0;

  @include for-phone-only {
    width: 100%;
    min-width: 370px;
  }

   &__head {
    @include flexbox(row, nowrap, center, space-between);
    min-height: 50px;
    width: 100%;
    margin: 0;
    padding: 8px;
    text-align: left;
    border-bottom: 3px solid $soft-gray-1;

    .sheet-title {
      @include text(14px, 500, 24px, rgba($soft-black, .9), normal);
      font-family: $font-input;
      letter-spacing: 0.8px;
      text-align: left;
    }

    .sheet-options--button {
      width: 30px;
      height: 30px;
      margin: 0;
      border: 1px solid rgba($soft-gray-1, .2);
      border-radius: 6px;
      // box-shadow: $button-shadow;

      &:hover {
        .button-icon {
          color: $blue-scale-4;
        }
      }

      .button-icon {
        color: rgba($soft-gray-1, .9);
      }
    }
  }

  &__formContent {
    min-height: fit-content;
    transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
    margin: 16px 0;
    padding: 0 16px;
    text-align: left;
  }

  &__actions {
    @include flexbox(row, nowrap, center, space-between);
    width: 100%;
    margin: 0;
    padding: 8px;
    border-top: 3px solid $soft-gray-1;

    .button--left {
      margin-right: auto;
    }
    .button--right {
      margin-left: auto;
    }
  }

}

.styledMenu {
  width: 100%;
  max-width: 300px;
  // box-shadow: $button-shadow !important;
  border: 1px solid rgba($medium-gray, .4);
  border-radius: 12px !important;

  .styledMenu--containerList {
    width: 100%;
    max-width: 250px;
    height: 100%;
    overflow: hidden;
    padding: 0;
    text-align: left;

    &__head {
      width: 100%;
      min-height: 35px;
      max-height: 35px;
      border-bottom: 2px solid $soft-gray-1;
      padding: 0 8px;

      .head-title {
        @include text(.875rem, 500, 1.1rem, rgba($soft-gray-1, .9), lowercase);
        letter-spacing: 0.5px;
      }
    }

    .button--exclude {
      .button-icon {
        color: rgba($color-secondary, .8) !important;
      }
    }

    .item--clickable {
      cursor: pointer;
      background-color: rgba($soft-gray-1, .3);
    }

    &__item {
      width: inherit;
      max-height: 50px;
      border-bottom: 1px solid $soft-gray-1;
      padding: 4px 8px 4px 16px;

      &:hover {
        cursor: default;
        background-color: rgba($soft-gray-1, .3);
      }

      .item-title {
        @include text(13px, 500, 1.5em, rgba($soft-black, .9), lowercase);
        font-family: $font-input;
        letter-spacing: 0.5px;
        text-align: left;
      }

      .item-label {
        @include text(12px, 400, 1.2em, $gray-scale-2, lowercase);
        font-family: $font-input;
        letter-spacing: 0.5px;
        text-align: left;
      }
    }
  }
}

// ===
// Base slot Item
// ===
.list-item--default-slot {
  @include flexbox(row, nowrap, center, flex-start);
  width: 100%;
  padding: 0 8px;
  margin: 0;
  // border: 1px solid red;

  .image-content {
    @include flexbox(row, nowrap, center, center);
    margin: 0 24px 0 0 !important;

    .ic-base-avatar {
      width: 50px;
      height: 50px;
      object-fit: contain;
      border-radius: 50%;
    }
  }

  .name-content {
    min-width: 150px;
    padding: 4px;
    text-align: left;
    margin: 0 auto 0 0;
  }

  .item-date {
    margin: 0 16px;
    @include text(12px, 600, 24px, $blue-scale-2, lowercase);
    text-align: left;
    letter-spacing: 0.5px;
  }

  .item-typed {
    margin: 0 8px;
    padding: 0 8px;
    border: 1px solid $soft-gray-1;
    border-radius: 6px;
    background: rgba($soft-gray-1, .4);

    @include for-desktop-up {
      margin: 0 24px;
    }

    .label {
      @include text(12px, 600, 24px, $blue-scale-2, lowercase);
    }
  }

  .status-content {
    width: fit-content;
    min-width: 70px;
    align-self: center;
    margin: 0 24px;

    @include for-desktop-down {
      margin: 0 16px;
      max-width: 150px;
    }
    @include for-desktop-up {
      margin: 0 24px;
      max-width: 180px;
    }


    .badge-fy {
      padding: 0 8px;
      border: 1px solid $soft-gray-1;
      border-radius: 6px;
      background: rgba($soft-gray-1, .4);

      .badge-label {
        @include text(12px, 600, 24px, $color-text, lowercase);
        letter-spacing: 0.5px;
      }
    }

    @media screen and (max-width: 850px) {
      width: 40px;
      margin: 0 8px 0 8px;

      .badge-fy {
        display: none;
      }
    }
  }
}


// === animations && transitions ===
.fadelocal-enter-to,
.fadelocal-leave-active {
  animation: fading 0.7s cubic-bezier(.66,.14,.83,.67);
}
@keyframes fading {
  0% {
    opacity: 0.2;
    transform: translateY(0px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
.fadelocal-leave-to /* .fade-leave-active below version 2.1.8 */ {
  animation: fading reverse 0.6s cubic-bezier(.11,.67,.6,.86);
}

.minify-enter-to,
.minify-leave-active {
  animation: expand 0.2s cubic-bezier(.66,.14,.83,.67);
}
@keyframes expand {
  0% {
    opacity: 0.4;
    transform: scale(0.4);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.minify-leave-to /* .fade-leave-active below version 2.1.8 */ {
  animation: expand reverse 0.2s cubic-bezier(.11,.67,.6,.86);
}

// Sobreescrever vuetify
.v-menu__content {
  box-shadow: 0px 0px 9px 1px rgba(219,215,219,0.42) !important;
}

// Sobreescrever vue-material
.md-theme-default :not(input):not(textarea)::selection {
  background-color: #9AD2FF !important;
  color: #000000 !important;
}

.md-dialog-container {
  overflow: auto!important;
}

// ===
// Vendor
// ===

#nprogress .bar {
  background: $color-link-text;
}
//
//@font-face {
//  font-family: "MuseoSans";
//  src: url('/fonts/museo_sans/MuseoSans_300.otf');
//}

.multiselect__tag {
  background: $color-multiselect-tag-bg;
  color: $color-multiselect-tag-color;
}
