

















































































































































































.configContent .md-switch .md-switch-thumb .md-ripple {
  height: 26px!important;
  border: 1px solid red;
}
