




















@import '@design';
.button {
  margin: 0;
  padding-left: 16px;
  padding-right: 16px;
}
