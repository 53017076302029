// CONTENT
$color-primary: #07338c;
$color-secondary: #ff5052;

// bg
$color-soft-bg: rgba(
  $color: #f5f5f8,
  $alpha: 0.3,
);

// blue scale
$blue-scale-0: #65c3f3;
$blue-scale-1: #4eaff5;
$blue-scale-2: #0a84ff;
$blue-scale-3: #0069ff;
$blue-scale-4: #0054cc;
$incomplete-status: #5469d4;

// gray scale
$gray-scale-0: #e3eef0;
$gray-scale-1: #c3c3d2;
$gray-scale-2: #747789;

// TEXT
$color-text: #2b3342;
$color-heading-text: #2b3342;

$soft-text: #a9aaa9;
$medium-text: #5a5a5a;

$soft-brown: #ad9ead;

// LINKS
$color-link-text: #ffffff;
$color-link-text-active: $color-text;

// INPUTS
$color-input-border: lighten($color-heading-text, 50%);

// BUTTONS
$color-button-bg: $color-link-text;
$color-button-disabled-bg: darken(desaturate($color-button-bg, 20%), 10%);
$color-button-text: white;

// FCM
$color-footer-bg: #1f2633;
$color-footer-hr: #2b3342;
$color-footer-copyright: #7691c2;
$color-footer-link: #ffffff;

$color-form-bg: white;
$color-input-selected: #2b3341;
$color-error: #ff5052;
$color-input-button-primary: #ff5052;

$color-chamada-txt: white;

$color-menu-active: #07338c;
$color-menu-inactive: #a9aaa9;
$color-multiselect-tag-bg: #07338c;
$color-multiselect-tag-color: white;

$color-disabled: #a9aaa9;

// Company
$color-company-bg: #ffffff;

$color-submenu-bg: #07338c;
$color-submenu-color: #ffffff;

$color-bg-white: #ffffff;
$color-bg-mid: #4dc8f4;
$color-bg-dark: #263344;

// BORDERS
$color-border-gray: #a9aaa9;
$color-border-gray-ligth: #f6f8f9;
$color-border-gray-dark: #5a5a5a;
$color-border-red: #ff5052;

// CHAT BG
$chat-background: #ebf3f6;
$new-bg: #f1f4fa;

// ERROS COLORS
$color-warn: #ffcc00;

// SOFT
$soft-blue: #1e88e5;
$soft-primary: #0b4ed5;

$soft-gray-blue: rgba(209, 213, 238, 0.2);
$border-gray-blue: rgba(209, 213, 238, 0.3);

$soft-gray-0: #bdc3cf;
$soft-gray-1: #f6f8f9;
$soft-gray-3: #505b6f;

$soft-gray-bg: #f8f8fa;

$medium-gray: #a9aaa9;

$dark-gray: #5a5a5a;
$dark-gray2: #414141;
$dark-gray3: #2e2c2c;
$dark-gray4: #00000086;

$medium-green: #5fa7a0;

$soft-green: #0ea77b;
$soft-orange: #fea17d;
$dark-green: #086047;
$soluti-green: #107266;

$soft-black: rgba(0, 0, 0, 0.8);

$stripe-purple: #5469d4;

// ADMIN
// $soft-gray-bg: #f3f4f7;

$call-color: #086047;

// tips
$tip-purple: #5856d6;
$tip-orange: #ff9f0a;
$tip-black: #000210;
$tip-ocean: #073796;
$tip-green: #0ef29e;
$tip-red: #ff5052;
$tip-turquoise: #36c2c0;
