





























































































































@import '@design';

.lang-preview {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px solid $soft-gray-1;
  border-radius: 5px;
  padding: 4px;

  .button--lang {
    width: 24px;
    height: 24px;
    position: absolute;
    bottom: -4px;
    right: -8px;
    z-index: $layer-1;
    border: 1px solid $soft-gray-1;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px rgba($blue-scale-3, .3);
    background-color: rgba(#fff, .9);
    margin: 0;
  }

  .flag-image {
    z-index: $layer-root;
  }
}

.styleItem {
  border: 1px solid red;

  &:hover {
    cursor: pointer;
  }

  &__content-icon {
    width: fit-content;
    min-width: 60px;
    padding: 0;
    margin: 0;
    // border: 1px solid blue;
  }
}

.flag-image {
  border-radius: 3px;
  max-height: 32px;
  width: auto;
  max-width: 60px;
  object-fit: cover;
  // cursor: pointer;
}

