




























































@import '@design';

  .config {
    @extend %defaultAlert;

    width: 100%;
    padding: 10px;
    position: fixed;
    border: 1px solid red;
    display: flex;

    .minVersion {
      flex: 1;
      cursor: pointer;
    }

    .maintenance {
      flex: 1;
    }

    .closeButton {
      flex: 0;
      color: #bb2f2c;
      cursor: pointer;
      top: 9px;
      right: 30px;

      i {
        color: #bb2f2c!important;
      }
    }
  }




