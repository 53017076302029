@import '_mixins';
@import '_gridsys';

%contentHeaderTitle {
  @extend %fontContentHeaderTitle;
  margin-bottom: 20px;
}

%defaultPreview {
  box-shadow: 2px 2px 9px 0px #8888884f;
  position: relative;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .changeImage {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    margin-top: 42%;
    color: white;
    background-color: rgba(0, 0, 0, 0.71);
    text-align: center;
    display: none;

    span {
      align-self: center;
      margin: 0 auto;
    }
  }
  .removeImage {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    color: white;
    background-color: rgba(0, 0, 0, 0.71);
    display: none;
    align-items: center;
    justify-content: center;
  }

  &:hover .changeImage {
    display: flex;
  }
  &:hover .removeImage {
    display: flex;
  }
}

%defaultBoxF1 {
  .container {
    margin: 10px 8px 30px 8px;
    min-height: 600px;
    overflow-y: scroll;

    .content {
      box-shadow: 2px 2px 9px 0px #8888884f;
      // box-shadow: 0 0 1em #8888884f;

      // border: 1px solid $color-border-gray;
      border-radius: 5px;
      // margin: 0 auto;
      min-width: 600px;
      max-width: 1000px;
      @media screen and (max-width: 1000px) {
        max-width: 100%;
      }
      @media screen and (max-width: 600px) {
        min-width: 100%;
      }
      margin: 0 auto;
      text-align: left;
      padding: 49px;
      // background-color: $color-form-bg;

      .contentLoaded {
        .contentHeaderTitle {
          @extend %contentHeaderTitle;
        }
        .imagePreview {
          @extend %defaultPreview;
        }
        .fcmEditor {
          border: solid 1px rgba(0, 0, 0, 0.08);
        }
        .body {
          font-family: $font-input;
        }
        .error {
          margin-top: 20px;
          margin-bottom: 20px;
          color: $color-error;
        }
        .progressBar {
          margin-bottom: 20px;
        }
        .actions {
          margin-top: 35px;
          padding-bottom: 25px;
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          .leftButton {
            font-family: $font-primary-text;
            background-color: #d46f6d;
            // float: left;
            align-self: flex-start;
          }
          .leftButton[disabled] {
            background-color: #0000001f;
          }
          .centerButton {
            font-family: $font-primary-text;
            // float: center;
            align-self: center;
            // margin-left: 40px;
          }
          .rightButton {
            font-family: $font-primary-text;
            // float: right;
            align-self: flex-end;
          }
        }
      }
    }
  }
}

%defaultAlert {
  /*  color: white;
  background-color: #da312e80; */

  color: #da312e;
  background-color: white;

  //border: solid 2px #da312ecc;
  z-index: 1000;
  border-radius: 5px;
  font-weight: 600;
  font-size: 16px;
}

%defaultBorder {
  border: 1px solid $color-border-gray-ligth;
  border-radius: 5px;
}

%contentLabelTitle {
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 32px;
  letter-spacing: 0.3px;
  color: #707070;
}

$color-primary: #07338c;
$soft-gray-blue: rgba(209, 213, 238, 0.2);

// shadows
$soft-shadow: 0 6px 30px -2px #d5dbed !important;
$soft-shadow1: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
  rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;

$v-shadow: 0 -4px 16px 0 rgb(50 50 105 / 4%);

$button-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgba(0, 0, 0, 0.07),
  0 1px 1.5px 0 rgba(0, 0, 0, 0.05);

$primary-shadow: 0 0 0 1px rgba(#0a84ff, 0.3), 0 2px 4px 0 rgba(#0a84ff, 0.07),
  0 1px 1.5px 0 rgba(#0a84ff, 0.05);

$primary-shadow-hover: 0 0 0 1px rgba(#0a84ff, 0.8),
  0px 4px 4px 0 rgba(#0a84ff, 0.05), 0 20px 8px -8px rgba(#0a84ff, 0.09);

$secondary-shadow: 0 0 0 1px rgba(#ff5052, 0.3), 0 2px 4px 0 rgba(#ff5052, 0.07),
  0 1px 1.5px 0 rgba(#ff5052, 0.05);

$secondary-shadow-hover: 0 0 0 1px rgba(#ff5052, 0.5),
  0 4px 4px 0 rgba(#ff5052, 0.05), 0 20px 8px -12px rgba(#ff5052, 0.09);

$button-bezier: cubic-bezier(0.2, 0.85, 0.32, 1.2);

.dialog--theme {
  width: 100%;
  border-radius: 10px;
  padding: 0 !important;
  overflow: hidden;

  .menu-button {
    margin: 0 4px;
  }

  .dialog--inner {
    width: inherit;
    height: fit-content;
    box-shadow: $button-shadow !important;
    border: 1px solid $soft-gray-1;
    border-radius: 10px;
    overflow: hidden;
    background-color: white;

    %inline-display {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
    }

    &__header {
      @extend %inline-display;
      min-height: 50px;
      border-bottom: 2px solid $soft-gray-1;
      padding: 16px;
      // border: 1px solid red;
    }

    &__list {
      padding: 16px 8px;
      // border: 1px solid red;

      &__item {
        display: inline-flex;
        align-items: center;
        max-height: 50px;
        width: 100%;
        padding: 8px 10px;
        margin: 0 0 4px 0;
        // border: 1px solid red;

        .item--content {
          height: inherit;
          width: 100%;
          text-align: left;
          padding: 0 0 0 8px;
          // border: 1px solid red;

          .item-input {
            margin: 0;
            width: 100%;
            border-bottom: 2px dotted $soft-gray-1;
          }

          .item-icon {
            margin: 0 8px 0 0;
          }
        }

        .item--action {
          min-width: 40px;

          .button--remove {
            width: 32px;
            min-width: 32px;
            height: 32px;
            border-radius: 8px;
            margin: 0 0 0 auto;
            box-shadow: 0 0 0 1px rgba($color-secondary, 0.5),
              0 1px 1px 0 rgba($color-secondary, 0.5),
              0 1px 1.5px 0 rgba($color-secondary, 0.5);

            .button-icon {
              color: $color-secondary;
            }
          }
        }
      }
    }

    &__actions {
      @extend %inline-display;
      min-height: 50px;
      border-top: 2px solid $soft-gray-1;
      padding: 8px 10px;
    }
  }
}

// #flex
%flexrow {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

%flexcol {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

// #buttons
$button-height: 35px;
$button-min-width: 64px;
$button-padding: 4px 16px;
$button-radius: 6px;
$button-font-size: 13px;

%button-props {
  height: $button-height;
  min-width: $button-min-width;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  padding: $button-padding;
  border-radius: $button-radius;
}

.app-button {
  @extend %button-props;

  // box-shadow: $button-shadow;
  // border: 1px solid $soft-gray-blue;

  transition: box-shadow 0.08s ease-in, color 0.08s ease-in, filter 50000s,
    -webkit-filter 50000s;
  background: #fff;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: default;
    border: 1px solid $soft-gray-1;
    box-shadow: $button-shadow;
    background-color: rgba($soft-gray-1, 0.9);

    .button-icon,
    .button-label {
      color: $gray-scale-2;
    }
  }

  .button-icon {
    color: $color-text;
  }

  .icon--left {
    margin-right: 8px;
  }

  .icon--right {
    margin-left: 8px;
  }

  .button-label {
    @include text($button-font-size, 600, 24px, $color-text, capitalize);
    font-family: 'Source Sans Pro', sans-serif;
    letter-spacing: 0.5px;
  }
}

.button-primary {
  @extend %button-props;

  background-color: rgba($blue-scale-2, 0.9);
  box-shadow: $primary-shadow;
  border: 1px solid #e5ebff;
  transition: background-color 0.3s $button-bezier;

  &:hover {
    &:not(:disabled) {
      cursor: pointer;
      box-shadow: $primary-shadow-hover;
    }
  }

  &:disabled {
    cursor: default;
    border: none;
    box-shadow: $button-shadow;
    background-color: rgba($soft-gray-1, 0.9);

    .button-icon,
    .button-label {
      color: $gray-scale-2;
    }
  }

  .button-icon,
  .button-label {
    color: #fff;
  }

  .icon--left {
    margin-right: 8px;
  }

  .icon--right {
    margin-left: 8px;
  }

  .button-label {
    @include text($button-font-size, 600, 24px, #fff, capitalize);
    font-family: $font-input;
    letter-spacing: 0.5px;
    text-align: center;
  }
}

.button-primary-approve {
  @extend %button-props;

  border: none;
  border-radius: 6px;
  color: $blue-scale-2;
  border: 1px solid #fff;
  box-shadow: $primary-shadow;
  background-color: rgba($blue-scale-2, 0.1);
  transition: all 0.2s $button-bezier;
  // margin: 0 8px;

  &:hover {
    &:not(:disabled) {
      cursor: pointer;
      background-color: rgba($blue-scale-2, 0.1);
      box-shadow: $primary-shadow-hover;
    }
  }

  &:disabled {
    cursor: default;
    border: 1px solid $soft-gray-1;
    box-shadow: $button-shadow;
    background-color: rgba($soft-gray-1, 0.9);

    .button-icon,
    .button-label {
      color: $gray-scale-2;
    }
  }

  .button-icon {
    color: $blue-scale-2;
  }

  .icon--left {
    margin-right: 4px;
  }

  .icon--right {
    margin-left: 4px;
  }

  .button-label {
    @include text($button-font-size, 600, 24px, $blue-scale-2, capitalize);
    letter-spacing: 0.5px;
    text-align: center;
  }
}

.button-secondary {
  @extend %button-props;

  border: none;
  border-radius: 6px;
  color: $color-secondary;
  border: 1px solid #fff;
  box-shadow: $secondary-shadow;
  background-color: rgba($color-secondary, 0.1) !important;
  transition: all 0.2s $button-bezier;
  // margin: 0 8px;

  &:hover {
    &:not(:disabled) {
      cursor: pointer;
      background-color: rgba($color-secondary, 0.1);
      box-shadow: $secondary-shadow-hover;
    }
  }

  &:disabled {
    cursor: default;
    border: 1px solid $soft-gray-1;
    box-shadow: $button-shadow;
    background-color: rgba($soft-gray-1, 0.9);

    .button-icon,
    .button-label {
      color: $gray-scale-2;
    }
  }

  .button-icon {
    color: $color-secondary;
  }

  .icon--left {
    margin-right: 4px;
  }

  .icon--right {
    margin-left: 4px;
  }

  .button-label {
    @include text($button-font-size, 600, 24px, $color-secondary, capitalize);
    letter-spacing: 0.5px;
    text-align: center;
  }
}

.button-dangerous {
  @extend %button-props;

  background-color: rgba($color-secondary, 0.1);
  &:hover {
    background-color: rgba($color-secondary, 0.2);
  }
  .button-icon,
  .button-label {
    @include text($button-font-size, 600, 24px, $color-secondary, capitalize);
    color: $color-secondary;
  }
}

// #skins
.button--soft {
  @extend %button-props;

  border: none;
  border-radius: 6px;
  padding: $button-padding;

  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: default;
    background-color: rgba($soft-gray-1, 0.9);

    .button-icon,
    .button-label {
      color: $gray-scale-2;
    }
  }

  .button-icon {
    color: $color-primary;
  }

  .icon--left {
    margin-right: 8px;
  }

  .icon--right {
    margin-left: 8px;
  }

  .button-label {
    @include text(12px, 600, 24px, $color-primary, capitalize);
    font-family: 'Source Sans Pro', sans-serif;
    letter-spacing: 0.5px;
  }
}

.button--softBlue {
  // margin-left: auto;
  box-shadow: $primary-shadow;
  background-color: rgba($blue-scale-2, 0.1) !important;
  &:hover {
    background-color: rgba($blue-scale-2, 0.1) !important;
  }

  .button-icon,
  .button-label {
    color: $blue-scale-2;
  }
}

.button--right {
  margin-left: auto;
}

.button--left {
  margin-right: auto;
}

.dialog-button {
  height: 35px;
  min-width: 64px;
  @extend %flexrow;

  border: none;
  border-radius: 6px;
  box-shadow: none;
  padding: $button-padding;

  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);

  .icon--left {
    margin-right: 6px;
  }
  .icon--right {
    margin-left: 6px;
  }

  .button-icon {
    color: $soft-black;
    font-weight: 600px;
  }

  .button-label {
    @include text(13px, 600, 24px, $soft-black, capitalize);
    font-family: $font-input;
    letter-spacing: 0.5px;
  }
}

.dialog-button--next {
  margin-left: auto;
  box-shadow: $primary-shadow;
  background-color: rgba($blue-scale-2, 0.1);
  &:hover {
    background-color: rgba($blue-scale-2, 0.1);
  }

  .button-icon,
  .button-label {
    color: $blue-scale-2;
  }
}

.dialog-button--prev {
  margin-right: auto;
  background-color: rgba($soft-gray-blue, 0.2);
  &:hover {
    background-color: rgba($soft-gray-blue, 0.2);
  }

  .button-label {
    color: $gray-scale-2;
  }
}

.newmorph-button {
  background: linear-gradient(
    344.05deg,
    rgb(255, 255, 255) 0%,
    rgb(230, 237, 252) 53.55%
  );
  border: none;
  border-radius: 10px;
  box-sizing: border-box;
  box-shadow: rgb(217 225 242) 10px 10px 20px,
    rgb(255 255 255 / 50%) -10px -10px 20px, rgb(211 221 240) 5px 5px 10px,
    rgb(255 255 255) -3px -3px 5px, rgb(255 255 255) 2px 2px 2px inset,
    rgb(211 221 240) -2px -2px 2px inset;
  cursor: pointer;
  margin: auto;
  padding: 0px;
  width: fit-content;
  height: 40px;
  min-height: 40px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  transition: all 0.2s ease-in-out 0s;

  &:hover {
    box-shadow: rgb(217 225 242) 10px 10px 20px,
      rgb(255 255 255 / 50%) -10px -10px 20px, rgb(172 188 219) 10px 10px 30px,
      rgb(255 255 255) -3px -3px 5px, rgb(255 255 255) 2px 2px 2px inset,
      rgb(211 221 240) -2px -2px 2px inset;
  }

  .button-label {
    color: rgb(41, 52, 73);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    margin: 0px 20px 0px 12px;
    white-space: nowrap;
  }
}

// #icons
.icon-primary {
  color: $color-primary;
}

.icon-secondary {
  color: $color-secondary;
}

.icon--left {
  margin: 0 8px 0 0;
}

.icon--right {
  margin: 0 0 0 8px;
}

// #texts
.error-color {
  color: $color-error;
}

// #animations

// #blog
.blog--post-item {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  height: fit-content;
  max-width: 300px;
  text-align: left;

  margin: 0 32px 24px 0;
  // border: 1px solid $soft-gray-1;
  border-radius: 6px;

  &:hover {
    cursor: default;
    // box-shadow: $button-shadow;
    // transform: scale(1.02);
    transition: all 0.3s ease-in;
  }

  $inner-padding: 8px 0;

  &__image-container {
    width: 100%;
    height: auto;
    border-radius: 0;
    overflow: hidden;
    margin: 0 0 8px 0;
    background-color: $soft-gray-1;

    img,
    .post--image {
      width: inherit;
      max-height: 320px;
      object-fit: cover;
    }
  }

  &__header {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 60px;
    width: 100%;
    padding: $inner-padding;
    margin: 0 0 8px 0;
    text-align: left;

    .post--date {
      font-family: 'Roboto', sans-serif;
      font-size: 0.875rem; // 10 px
      letter-spacing: 1.5px;
      font-weight: 500;
      color: rgba($gray-scale-2, 0.8);
      margin: 0 0 8px 0;
    }

    .post--title {
      font-family: 'Roboto', sans-serif;
      font-size: 1.375rem; // 22 px
      font-weight: 500;
      line-height: 1.5rem;
      color: $soft-black;

      max-width: 280px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-overflow: ellipsis;
    }
  }

  &__content {
    padding: $inner-padding;

    .post--paragraph {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1rem; // 16 px
      letter-spacing: 0.35px;
      text-overflow: ellipsis;
      color: rgba($medium-text, 0.8);
      margin: 0;
    }
  }

  &__actions {
    padding: $inner-padding;
    text-align: left;

    .post--link {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 0.875rem; // 16 px
      letter-spacing: 0.15px;
      font-weight: 600;
      text-decoration: none;
      color: rgba($color-secondary, 0.9);

      &:hover {
        cursor: pointer;
      }
    }
  }
}

// #landings
%landing--contents {
  z-index: $layer-2;
  max-width: 600px !important;

  .my-title {
    @include text(48px, 600, 1.08349, $color-text, normal);
    font-family: 'Roboto Bold', sans-serif;
    font-display: swap;
    letter-spacing: -.003em;
    text-align: left;
    margin: 0 0 24px 0;

    @include for-phone-only {
      @include text(48px, 600, 1.08349, $color-text, normal);
      margin: 0 0 24px 0;
    }
  }

  .paragraph {
    @include text(25px, 500, 1.32, rgba($dark-gray, .8), normal);
    font-family: 'SansPro regular', sans-serif;
    font-display: swap;
    font-style: normal;
    text-align: left;
    letter-spacing: -0.3px;
    margin: 0 0 24px 0;

    @include for-phone-only {
      @include text(21px, 500, 1.19048, rgba($dark-gray, .8), normal);
      letter-spacing: .011em;
      margin: 0 0 24px 0;
    }
  }

  .memo {
    @include text(16px, 700, 1.32, $color-text);
    max-width: 850px;
    width: 100%;
    text-align: left;
    font-family: 'Roboto', sans-serif;
    font-display: swap;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: .2px;
    color: $color-text;

    @include for-phone-only {
      @include text(14px, 600, 1.23, $color-text, normal);
      margin: 24px 0;
      letter-spacing: 0.1px;
    }
  }

  .storelinks-container {
    margin-top: 10px;
    @include flexbox(row, nowrap, center, center);

    @include for-phone-only {
      @include flexbox(row, wrap, center, center);
      margin: 24px 0;
      letter-spacing: 0.1px;
    }

    .store-brands {
      max-width: 156px;
      height: 48px;
      object-fit: contain;
      cursor: pointer;
      margin: 10px 20px 10px 0;
    }
  }

  .call-to-solutions {
    @include flexbox(row, nowrap, center, space-between);
    padding: 8px 0;
    width: fit-content;
    min-width: 280px;
    background-color: rgba(#fff, 1) !important;

    @include for-phone-only {
      width: 100%;
      flex-flow: column nowrap;
      margin: 0 0 0 -8px;
      background-color: rgba(#fff, .1) !important;
      // border: 1px solid red;

      .button--call {
        margin: 0 0 24px 0 !important;
        width: 100%;
        max-width: 300px;
        background-color: rgba($color-secondary, .8);

        .button-label {
          color: #fff;
          text-transform: uppercase;
        }
      }
    }

    .button--call {
      margin: 0 24px 0 0;
    }
  }

  .tip--red {
    background-color: $tip-red !important;
  }
  .tip--orange {
    background-color: $tip-orange !important;
  }
  .tip--green {
    background-color: $tip-green !important;
  }
  .tip--black {
    background-color: $tip-black !important;
  }
  .tip--ocean {
    background-color: $tip-ocean !important;
  }
  .tip--purple {
    background-color: $tip-purple !important;
  }
  .tip--turquoise {
    background-color: $tip-turquoise !important;
  }

  .tips-container {
    max-width: 550px;
    height: fit-content;
    @include flexbox(row, wrap, flex-start, space-between);
    // border: 1px solid red;
    margin: 24px 0 0 0;

    .tip-item {
      @include flexbox(row, nowrap, center, flex-start);
      height: fit-content;
      min-height: 35px;
      width: 100%;
      max-width: 250px;
      padding: 0 8px 8px 0;
      margin: 0 16px 16px 0;

      @include for-phone-only {
        // border: 1px solid red;
        margin: 0 16px 24px 0;
      }

      .tip-badge {
        height: 32px;
        width: 32px;
        min-width: 32px;
        border-radius: 50%;
        background: rgba($blue-scale-2, 0.4);
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 8px 0 0;

        @include for-phone-only {
          // border: 1px solid red;
          margin: 0 16px 0 0;
        }


        $icon-size: 28px;
        img {
          height: $icon-size;
          width: $icon-size;
          object-fit: cover;
        }
      }

      .tip-text {
        @include text(14px, 400, 1.32, rgba($dark-gray, .8), capitalize);
        max-width: 600px;
        text-align: left;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: .5px;

        @include for-phone-only {
          @include text(14px, 400, 1.23, rgba($dark-gray, .8), normal);
          margin: 0;
          letter-spacing: 0.1px;
        }
      }
    }
  }

}

%landing-hero {
  height: 100%;
  display: flex;
  z-index: $layer-root;
  // border: 1px solid purple;

  @include for-phone-only {
    display: none;
  }

  .imgBox {
    position: relative;
    // max-width: 748px;
    // height: auto;
    width: 500px;
    height: 500px;

    @include for-phone-only {
      width: 250px;
      height: auto;
    }

    .imgBG {
      position: absolute;
      width: 100%;
      height: 100%;
      .imgBGLine {
        width: 100%;
        height: 50%;
        display: flex;
        .imgBGWhite  {
          width: 50%;
          height: 100%;
          background-color: #FFF;
        }
        .imgBGColor{
          width: 50%;
          height: 100%;
          background-color: #f5f5f8;

        }
      }

      @media screen and (max-width: 720px){
        display: none;
      }

    }

  }

  .store-brands {
    position: relative;
    top: 0;
    bottom: 0;
    width: auto;
    max-width: 280px;
    height: inherit;
    object-fit: contain;
    margin-top: auto;
    // border: 1px solid red;

    @media screen and (max-width: 720px) {
      margin-left: 50px;
      margin-top: 30px;
    }

    @media screen and (max-width: 400px) {
      margin-left: 0px;
      width: 100%;
    }

  }
}

%hero--left {
  margin: 0 32px 0 0;
}

%hero--rigth {
  margin: 0 0 0 32px;
}

.landing--section-inline {
  width: 100%;
  height: max-content;
  background-color: #ffffff;
  padding: 32px;
  // border: 1px solid red;

  @include for-phone-only {
    width: 100vw;
    margin: 0;
    padding: 32px 0;
  }

  @include for-desktop-up {
    // border: 1px solid blue;
  }

  .inline-header {
    width: 100%;
    min-height: 30px;
    padding: 24px 0;

    @include for-phone-only {
      width: 100%;
      padding: 16px 24px;
      text-align: center;
    }
  }

  .section-container {
    @include flexbox(row, nowrap, flex-start, center);
    padding: 40px 0;
    // border: 1px solid blue;

    @include for-phone-only {
      @include flexbox(column, nowrap, center, center);
      padding: 8px 24px;
      // border: 1px solid blue;
    }

    @include for-desktop-up {
      // border: 1px solid blue;
    }

    .inline-item {
      @include flexbox(column, wrap, flex-start, flex-start);
      margin: 8px 32px;
      max-width: 350px;

      @include for-phone-only {
        @include flexbox(column, wrap, center, flex-start);
        width: 100%;
        padding: 8px;
      }

      @include for-desktop-up {
        @include flexbox(column, wrap, flex-start, space-between);
        margin: 8px 16px;
      }

      .image {
        width: 57px;
        height: 70px;
        object-fit: contain;
        margin: 0 0 16px 0;
        align-self: center;
        // border: 1px solid blue;

        @include for-phone-only {
          align-self: center;
          margin: 8px 0;
        }
      }

      .subtitle {
        @include text(18px, 600, 1.43, $color-text, normal);
        font-family: 'Roboto', sans-serif;
        letter-spacing: 0.5px;
        text-align: left;
        text-justify: initial;
        margin: 0 0 8px 0;
        font-family: sans-serif;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: #2c2c2e;
        font-display: swap;

        @include for-phone-only {
          @include text(20px, 600, 1.43, $color-text, normal);
          text-align: left;
          margin: 8px 0;
        }

        @include for-desktop-down {
          text-align: center;
        }
      }

      .paragraph {
        @include text(16px, 400, 1.43, $gray-scale-2, normal);
        text-align: left;
        font-family: 'MuseoSans', sans-serif;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        max-width: 350px;
        margin: 0;
        font-display: swap;

        @include for-phone-only {
          @include text(16px, 400, 1.43, $gray-scale-2, normal);
          text-align: left;
          margin: 8px 0;
        }

        @include for-desktop-down {
          text-align: center;
        }
      }
    }
  }
}

.landing--section-presentation {
  width: 100%;
  min-height: 90vh;
  display: flex;
  position: relative;
  // border: 1px solid red;

  $max-cw: 600px;

  @include for-phone-only {
    padding: 2px;
    margin: 0;
    height: 100%;
    width: 100vw;
    overflow: hidden;
  }

  @include for-desktop-up {
    height: fit-content;
    max-height: 900px;
    min-height: 700px;
    // border: 2px solid green;
  }

  .hero--image-coupled {
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: $layer-root;
    margin: 0;
    overflow: hidden;
    // border: 1px solid blue;

    @include for-phone-only {
      height: 100%;
      width: 100%;
      // border: 2px solid green;
    }

    @include for-desktop-up {
      width: 100%;
      height: 100%;
      // border: 1px solid blue;
    }

    .image--decoration {
      width: 100%;
      height: 100%;
      object-fit: contain;

      position: absolute;
      right: 0;
      top: 0;
      z-index: $layer-root;
    }

    .image--featured {
      width: auto;
      height: 100%;
      // max-width: 100%;
      object-fit: cover;

      position: absolute;
      right: 0;
      top: 0;
      margin: 0;
      padding: 0;
      z-index: $layer-1;
      // border: 1px solid purple;

      @include for-phone-only {
        height: 80%;
        width: 100vw;
        object-fit: cover;
        top: 20%;
      }

      @include for-desktop-up {
        height: 100%;
        width: auto;
        max-width: 100%;
      }
    }
  }

  .section-container {
    @extend %landing--contents;
    @include flexbox(column, nowrap, flex-start, center);
    min-height: 100% !important;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    padding: 0 32px;
    z-index: $layer-1;
    // border: 1px solid purple;

    @include for-phone-only {
      @include flexbox(column, nowrap, flex-start, center);
      padding: 0;
      margin: 0;
      height: 100%;
      width: 100vw;
      background-color: rgba(#fff, .8);
    }

    @include for-desktop-up {
      min-height: 700px;
    }

    .content {
      @include flexbox(column, nowrap, flex-start, flex-start);
      max-width: $max-cw;
      //height: 100%;
      flex: 1 1 0;
      padding: 32px 45px;
      margin: 0 0 0 32px;

      @include for-phone-only {
        @include flexbox(column, nowrap, flex-start, flex-start);
        height: inherit;
        width: 100vw;
        flex: 1 1 0;

        padding: 40px 24px;
        margin: 0;
        background: rgba(#fff, 0.3);
        backdrop-filter: 8px;
        text-align: left;
        // border: 1px solid red;
      }

      @include for-desktop-up {
        max-width: 750px;
      }

    }
  }
}

.landing--section-hero_left {
  width: 100%;
  padding: 60px 32px;

  @include for-phone-only {
    padding: 0;
  }

  .section-container {
    @include flexbox(row, nowrap, center, center);
    width: 100%;
    padding-right: 300px;

    @include for-phone-only {
      @include flexbox(row, wrap, center, center);
      padding: 0;
    }

    @include for-desktop-down {
      padding-right: 0px;
      padding-left: 0px;
    }

    .hero {
      @extend %landing-hero;
      @extend %hero--left;
    }

    .content {
      @extend %landing--contents;
      @include flexbox(column, nowrap, flex-start, center);
      max-width: 600px;
      margin: 0;

      @include for-phone-only {
        padding: 32px;
        min-height: 500px;
        margin: 0;
      }

      @media screen and (max-width: 320px) {
        width: 100%;
      }
    }

  }
}

.landing--section-hero_rigth {
  width: 100%;
  padding: 60px 32px;

  @include for-phone-only {
    padding: 0;
  }

  .section-container {
    @include flexbox(row-reverse, nowrap, center, center);
    width: 100%;

    @include for-phone-only {
      padding: 32px 24px;
    }

    .hero {
      @extend %landing-hero;
      @extend %hero--rigth;
      height: 100%;
      display: flex;

      // border: 1px solid purple;

      @include for-phone-only {
        display: none;
      }

      .imgBox {
        position: relative;
        // max-width: 748px;
        // height: auto;
        width: 500px;
        height: 500px;

        @include for-phone-only {
          width: 250px;
          height: auto;
        }

        .imgBG {
          position: absolute;
          width: 100%;
          height: 100%;
          .imgBGLine {
            width: 100%;
            height: 50%;
            display: flex;
            .imgBGWhite  {
              width: 50%;
              height: 100%;
              background-color: #FFF;
            }
            .imgBGColor{
              width: 50%;
              height: 100%;
              background-color: #f5f5f8;

            }
          }

          @media screen and (max-width: 720px){
            display: none;
          }
        }
      }

      .store-brands {
        position: relative;
        top: 0;
        left: 0;
        max-width: 250px;
        height: auto;
        object-fit: contain;
        // margin-top: 130px;

        @media screen and (max-width: 720px) {
          margin-left: 50px;
          margin-top: 30px;
        }

        @media screen and (max-width: 400px) {
          margin-left: 0px;
          width: 100%;
        }

      }
    }

    .content {
      @extend %landing--contents;
      @include flexbox(column, wrap, flex-start, center);
      max-width: 600px;
      margin: 0;

      @include for-phone-only {
        @include flexbox(column, wrap, center, flex-start);
        padding: 32px;
        min-height: 500px;
        margin: 0;
      }

      @media screen and (max-width: 320px) {
        width: 100%;
      }

    }
  }
}

.landing--section-recents {
  width: 100%;
  padding: 32px;

  @include for-phone-only {
    padding: 0;
  }

  @include for-desktop-up {

  }

  .section-container {
    @include flexbox(column, nowrap, center, flex-start);
    width: 100%;
    padding: 32px 0;
    // border: 1px solid red;

    @include for-phone-only {
      padding: 32px 24px;
    }

    @include for-desktop-up {

    }

    .recents-content {
      width: fit-content;
      // max-width: 600px;
      // border: 1px solid red;

      @include for-phone-only {
        @include flexbox(column, wrap, center, flex-start);
        padding-left: 0px;
        margin: 0 10px;
      }

      @include for-desktop-up {

      }

      .recents-header {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: flex-start;
        text-align: left;
        margin: 0 0 32px 0;

        @include for-phone-only {
          padding: 0 24px;
          margin: 0 0 24px 0;
        }

        @include for-desktop-up {

        }

        .headline {

        }

        .my-title-2 {

        }
      }

      .wrapper--inline {
        @include flexbox(row, wrap, flex-start, center);
        width: 100%;
        // height: max-content;
        min-height: 400px;
        // border: 1px solid red;
      }

      .blog--post-item {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: flex-start;

        // height: fit-content;
        max-width: 300px;
        text-align: left;

        margin: 0 32px 8px 0;
        border: 1px solid $soft-gray-1;
        border-radius: 6px;

        &:hover {
          cursor: pointer;
          // box-shadow: $button-shadow;
          // transform: scale(1.01);
          transition: all .3s ease-in;
        }

        @include for-phone-only {
          justify-content: center;
          margin: 0 0 16px 0;
        }

        @include for-desktop-up {

        }

        &__image-container {
          width: 100%;
          height: auto;
          border-radius: 6px 6px 0 0;
          overflow: hidden;

          img {
            width: inherit;
            max-height: 320px;
            object-fit: cover;
          }
        }

        &__header {
          display: flex;
          flex-flow: column nowrap;
          align-items: flex-start;
          justify-content: flex-start;
          padding: 8px;
        }

        &__content {
          padding: 8px;
          // border: 1px solid red;
        }

        &__actions {
          padding: 8px;
        }
      }
    }
  }
}
