





























@import '@design';

html {
  padding: 0;
  margin: 0;
  width: 100%;
}

.visitant-page {
  width: 100%;
  height: 100%;
  @include flexbox(column, nowrap, flex-start, flex-start);
  // border: 1px solid blue;

  @include for-phone-only {
    width: 100vw;
  }

  &__nav-bar {
    flex: 0 0 auto;
    // border: 1px solid red;
  }

  &__container {
    // flex: 1 0 auto;
    width: 100%;
    height: 100%;

    @include for-phone-only {
      width: 100vw;
      height: 100%;
      overflow-x: hidden;
    }
  }

  &__footer {
    display: none;
    flex-shrink: 0;
  }
}

