$layer-negative-z-index: -1;
$layer-page-z-index: 1;
$layer-dropdown-z-index: 2;
$layer-modal-z-index: 3;
$layer-popover-z-index: 4;
$layer-tooltip-z-index: 5;

$layer-negative: -1;
$layer-root: 0;
$layer-1: 10;
$layer-2: 50;
$layer-3: 100;
