

%defaultRow {
  display: flex;
  flex-flow: row wrap;
}

%defaultColumn {
  display: flex;
  flex-flow: column wrap;
}

%centerChildren {
  display: flex;
  align-items: center;
  justify-content: center;
}

._col {
  @extend %defaultColumn;
  align-items: flex-start;
  justify-content: flex-start;
}

._row {
  @extend %defaultRow;
  align-items: center;
  justify-content: flex-start;
}

._wrap {
  flex-wrap: wrap;
}

._no-wrap {
  flex-wrap: nowrap;
}

@mixin flexbox($orientation:null, $wrap:null, $align:null, $justify:null) {

  display: flex;

  @if $orientation != null {
    flex-direction: $orientation;
  } @else {
    flex-direction: column;
  }

  @if $wrap != null {
    flex-wrap: $wrap;
  } @else {
    flex-wrap: nowrap;
  }

  @if $justify != null {
    justify-content: $justify;
  } @else {
    justify-content: flex-start;
  }

  @if $align != null {
    align-items: $align;
  } @else {
    align-items: flex-start;
  }

}


// MARGINS
.-mb8, .mb-b8 {
  margin-bottom: 8px;
}
.mg-b16 {
  margin-bottom: 16px;
}
.mg-b32 {
  margin-bottom: 32px;
}

.-ml8, .mb-l8 {
  margin-left: 8px;
}
.-mr8, .mg-r8 {
  margin-right: 8px;
}
